@mixin FontStyle($size, $weight, $color) {
    font-size: $size;
    font-weight: $weight;
    color: $color;
}
.transctionItem {
    background: rgba(255, 255, 255, 0.45);
    backdrop-filter: blur(124.66px);
    border-radius: 14.25px;
    width: 100%;
    min-height: 82px;
    cursor: pointer;
    margin-bottom: 16px;
}
.transctionItemGrid {
    display: grid;
    grid-template-columns: 0.2fr 1fr 0.5fr;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    gap: 6px;
}
.transctionLeftItem {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
}
.transctionDate {
    @include FontStyle(14px, normal, rgba(0, 0, 0, 0.45));
    display: flex;
    align-items: center;
    gap: 3px;
    margin-right: 16px;
}
.transctionTitle {
    @include FontStyle(16px, 400, #4164ab);
}
.transctionItemGrid .transctionIcon {
    width: 35px;
    height: 35px;
    background: #ffffff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
}
.chargeback_status {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 15px;
    border-bottom-left-radius: 14.25px;
    border-bottom-right-radius: 14.25px;
}
.chargeback_status_open_parent {
    @include FontStyle(12px, 500, #c62828);
}
.chargeback_status_open_child {
    @include FontStyle(12px, 500, #c62828);
    border: 0.75px solid #c62828;
    border-radius: 4px;
    padding: 2px 6px;
}
.chargeback_status_closed_parent {
    @include FontStyle(12px, 500, #1890ff);
}
.chargeback_status_closed_child {
    @include FontStyle(12px, 500, #1890ff);
    border: 0.75px solid #1890ff;
    border-radius: 4px;
    padding: 2px 6px;
}
.chargeback_status_under_review_parent {
    @include FontStyle(12px, 500, #ed6c02);
}
.chargeback_status_under_review_child {
    @include FontStyle(12px, 500, #ed6c02);
    border: 0.75px solid #ed6c02;
    border-radius: 4px;
    padding: 2px 6px;
}
@media screen and (min-width: 992px) {
    .transctionItemGrid {
        grid-template-columns: 0.2fr 2.5fr 0.5fr;
    }
}
